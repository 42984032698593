<template>
  <div class="full-screen flex-center body-wrapper">
    <div class="light-1"></div>
    <div class="light-2"></div>
    <section
      class="auth-card vertical-g-20 justify-content-center position-relative pt-2 pb-5"
      :class="isSuccess ? 'success' : 'failed'"
    >
      <div class="icon-status-wrapper position-absolute base-card p-4">
        <font-awesome-icon
          v-if="isSuccess"
          icon="check"
          class="text-white"
          size="lg"
        />
        <font-awesome-icon v-else icon="close" class="text-white" size="lg" />
      </div>
      <CRow class="my-5 justify-content-center">
        <p class="font-weight-bold">{{ message }}</p>
      </CRow>
      <CRow class="px-md-4 px-2">
        <CCol col>
          <div>
            <p class="text-muted">مبلغ پرداخت شده</p>
            <p class="text-nowrap">
              <span class="text-danger font-weight-bold"
                >{{ price.toLocaleString() }}
              </span>
              <span class="text-muted">ریال</span>
            </p>
          </div>
        </CCol>
        <CCol col="auto px-2">
          <hr class="vertical h-100" />
        </CCol>
        <CCol col>
          <div>
            <p class="text-muted">نوع پرداخت</p>
            <p class="text-nowrap font-weight-bold">
              پرداخت از طریق درگاه بانکی
            </p>
          </div>
        </CCol>
      </CRow>
      <CRow class="my-4 gap-5">
        <CRow class="w-100">
          <CCol col="auto" class="text-muted">وضعیت سفارش</CCol>
          <CCol col class="text-left">تایید شده</CCol>
        </CRow>
        <CRow class="w-100">
          <CCol col="auto" class="text-muted">شماره فاکتور</CCol>
          <CCol col class="text-left">{{ ordered_at }}</CCol>
        </CRow>
        <CRow class="w-100">
          <CCol col="auto" class="text-muted">کد پیگیری</CCol>
          <CCol col class="text-left font-weight-bold">{{ trackingCode }}</CCol>
        </CRow>
        <CRow class="w-100">
          <CCol>
            <small class="text-muted">
              {{ helpText }}
            </small>
          </CCol>
        </CRow>
      </CRow>
      <CRow class="justify-content-center">
        <a
          href="https://app.biochi.co/bank-result"
          color="primary"
          target="_blank"
          rel="noopener noreferrer"
          >بازگشت به صحرا
        </a>
      </CRow>
    </section>
  </div>
</template>
  
  
  <script>
import axios from "axios";
import { apiSuccessWithBankPayment } from "../../../constants/config";
export default {
  name: "AppIndicatorPaymentCheck",
  data() {
    return {
      status: "success",
      message: "پرداخت موفق!",
      price: 0,
      ordered_at: new Date().toLocaleDateString(),
      trackingCode: 0,
      helpText:
        "در صورت بروز مشکل، از طریق راه های ارتباطی موجود در بخش ارتباط با ما، با پشتیبان تماس بگیرید!",
    };
  },
  computed: {
    isSuccess() {
      return this.status === "success";
    },
  },
  methods: {
    async bindSuccessPaymentAmount(trans_id, id_get, buyServiceId) {
      this.loading = true;
      this.loadingText = "دریافت اطلاعات پرداخت";
      let model = {
        buyServiceId: buyServiceId,
        transId: trans_id,
        idGet: id_get,
      };
      let result = await axios
        .post(apiSuccessWithBankPayment, model, {})
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          if (err.response) console.log(err.response.data.Message);
        });
      if (result) {
        if (result.succeeded) {
          this.$notify({
            title: "پیام سیستم",
            text: ` خدمات ماهواره فعال شد<br /><br />`,
            type: "success",
          });
          this.price = result.data.amount;
          this.ordered_at = result.data.factorNumber;
          this.trackingCode = result.data.transactionCode;
          this.isSuccess;
        } else {
          this.message = "خطا در پرداخت";
          this.status = "failed";
          this.$notify({
            title: "پیام سیستم",
            text: `${result.message} <br /><br />`,
            type: "error",
          });
        }
      } else {
        this.message = "خطا در پرداخت";
        this.status = "failed";
        this.$notify({
          title: "پیام سیستم",
          text: `خطا در تایید پرداخت <br /><br />`,
          type: "error",
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    if (this.$route.query.trans_id) {
      this.bindSuccessPaymentAmount(
        this.$route.query.trans_id,
        this.$route.query.id_get,
        this.$route.query.factorId
      );
    } else {
      if (this.$route.query.trans_id == "0") {
        this.status = "failed";
        this.$notify({
          title: "پیام سیستم",
          text: `خطا در پرداخت رخ داده است <br /><br />`,
          type: "error",
        });
      }
    }
  },
};
</script>
  
<style scoped>
.full-screen {
  width: 100dvw;
  height: 100dvh;
}
.icon-status-wrapper {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.auth-card.success .icon-status-wrapper {
  background-color: var(--secondary);
}

.auth-card.failed .icon-status-wrapper {
  background-color: var(--danger);
}
</style>
  